<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            width="440"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 25em !important;">
                <!-- <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/ducks.svg" style="height: 10rem !important" /> -->
                <img src="/static/images/suspend-red.png" width="103px" height="109px" />
                <span class="fs-15 fw-600 pt-4">
                    Are you sure you want to {{type}} <span class="fw-600">{{full_name}}</span>?
                </span>
                <div class="d-flex aligin-items-center justify-content-center mt-5">  
                    <button
                        class="btn btn-outline-secondarys fw-600"
                        style="width: 80px;border-radius: 5px;"
                        @click="cancelSuspend"
                    >No</button>      
                    <button
                        type="button"
                        class="btn btn-new-success ml-3 fw-600"
                        style="width: 80px;border-radius: 5px;"
                        @click="suspendedEmploy"
                    >Yes</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name","full_name","index", "suspend_id", "type"],
    methods: {
        suspendedEmploy() {
            this.$emit("suspendedEmploy", {index: this.index, id: this.suspend_id});
        },
        cancelSuspend() {
            this.$emit("cancelSuspend");
        }
    },
}
</script>
<style>
     .btn-outline-secondarys {
        color: #fff;
        border: 1px solid #e82828 !important;
        background-color: #e82828;
    }
</style>